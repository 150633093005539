import MainBarBasic from './feature/_mainbar.basic/main';
import StatusBarBasic from './feature/_statusbar.basic/main';
import MenuApollo from './feature/_menu.apollo/main';

import LoginBasic from './feature/login.basic/main';
import LogoutBasic from './feature/logout.basic/main';
import PasswordBasic from './feature/password.basic/main';
import HistoryBasic from './feature/history.basic/main';
import Apollo from './feature/apollo.apollo/main';
import SiteBasic from './feature/site.basic/main';
import LogBasic from './feature/log.basic/main';
import GroupBasic from './feature/group.basic/main';
import AdministratorBasic from './feature/administrator.basic/main';
import ServerBasic from './feature/server.basic/main';
import NodeBasic from './feature/node.basic/main';

export function loadFeatures() {
    return {
        _menu: { apollo: MenuApollo },
        _mainbar: { basic: MainBarBasic },
        _statusbar: { basic: StatusBarBasic },
        login: { basic: LoginBasic },
        logout: { basic: LogoutBasic },
        password: { basic: PasswordBasic },
        history: { basic: HistoryBasic },
        apollo: { apollo: Apollo },
        site: { basic: SiteBasic },
        log: { basic: LogBasic },
        group: { basic: GroupBasic },
        administrator: { basic: AdministratorBasic },
        server: { basic: ServerBasic },
        node: { basic: NodeBasic }
    };
}

export function loadPlugins() {
    return {
    };
}