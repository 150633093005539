import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import StorageIcon from '@mui/icons-material/Storage';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';

import { load } from '../../main/App';

import { AbstractMenu } from '../../main/AbstractMenu';



class Menu extends AbstractMenu {

    constructor() {
        super([
            {
                title: window.lan._menu.title,
                items: [
                    { name: window.lan._menu.information, icon: (<HomeIcon fontSize="small" />), fun: () => { load('/apollo'); } },
                    {},
                    { name: window.lan._menu.server, icon: (<StorageIcon fontSize="small" />), fun: () => { load('/server'); }, right: "site" },
                    { name: window.lan._menu.node, icon: (<FlagIcon fontSize="small" />), fun: () => { load('/node'); }, right: "site" },
                    {},
                    { name: window.lan._menu.log, icon: (<FormatListBulletedIcon fontSize="small" />), fun: () => { load('/log'); }, right: "log" },
                    { name: window.lan._menu.group, icon: (<GroupIcon fontSize="small" />), fun: () => { load('/group'); }, right: "system" },
                    { name: window.lan._menu.administrator, icon: (<AccountCircleIcon fontSize="small" />), fun: () => { load('/administrator'); }, right: "system" }
                ]
            }
        ]);
    }

    filter(item) {
        if (!window.config.admin.right) return false;
        if (!item.right) return true;
        return window.config.admin.right[item.right] && window.config.admin.right[item.right] > 0;
    }
}



export default Menu;