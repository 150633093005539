import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { initialize_title, modify_title, get_url } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TooltipWrapper, TextInput, SelectInput, DateInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr, getUTCDateStr, checkEmail, checkJSON } from '../../main/Utility';

import dayjs from 'dayjs';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/node.basic', 'post', { name: '', language: window.language }, props.reference, 'add_diag', window.lan.node.add, window.lan.node.add_submit);
    }

    options() {
        const loptions = Object.entries(window.lan.language).map(([key, name]) => ({ name: name, value: key }));
        const soptions = [];
        window.config.buffer['server/server'].forEach((item) => {
            if (item.name === '') return;
            soptions.push({ name: item.name, value: item.id });
        });
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.node.add_name} fullwidth />
                <SelectInput form={this} id="language" label={window.lan.node.add_language} options={loptions} fullwidth />
                <SelectInput form={this} id="serverID" label={window.lan.node.add_server} options={soptions} fullwidth />
            </Stack>)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.node.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/node.basic', 'put', {}, props.reference, 'edit_diag', window.lan.node.edit, window.lan.node.edit_submit, 70);
    }

    openmain() {
        this.value._validTime = dayjs.unix(this.value.validTime);
        super.openmain();
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="name" label={window.lan.node.edit_name} fullwidth />
                <TextInput form={this} id="frontClient" label={window.lan.node.edit_front_client} fullwidth />
                <TextInput form={this} id="frontServer" label={window.lan.node.edit_front_server} fullwidth />
            </Stack>),
            (<Stack direction="row" spacing={5}>
                <TooltipWrapper input={<TextInput form={this} id="email" label={window.lan.node.edit_email} fullwidth />} tooltip={window.lan.node.edit_email_tip} />
                <TextInput form={this} id="backClient" label={window.lan.node.edit_back_client} fullwidth />
                <TextInput form={this} id="backServer" label={window.lan.node.edit_back_server} fullwidth />
            </Stack>),
            (<Stack direction="row" spacing={5}>
                <SelectInput form={this} id="language" label={window.lan.node.edit_language} options={
                    Object.entries(window.lan.language).map(([key, name]) => ({ name: name, value: key }))
                } fullwidth />
                <TooltipWrapper input={<TextInput form={this} id="license" label={window.lan.node.edit_license} fullwidth />} tooltip={window.lan.node.edit_license_tip} />
                <TooltipWrapper input={<DateInput form={this} id="_validTime" label={window.lan.node.edit_valid_time} fullwidth />} tooltip={window.lan.node.edit_valid_time_tip} />
            </Stack>),
            (<TextInput form={this} id="featureList" label={window.lan.node.edit_feature} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="pluginList" label={window.lan.node.edit_plugin} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="configuration" label={window.lan.node.edit_configuration} fullwidth multiline rows={4} />),
            (<TextInput form={this} id="remark" label={window.lan.node.edit_remark} fullwidth multiline rows={4} />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length < 1 || value.length > 31 ? window.lan.node.err[0] : '';
        if (id === 'frontClient') return value.length > 127 ? window.lan.node.err[1] : '';
        if (id === 'frontServer') return value.length > 127 ? window.lan.node.err[2] : '';
        if (id === 'backClient') return value.length > 127 ? window.lan.node.err[3] : '';
        if (id === 'backServer') return value.length > 127 ? window.lan.node.err[4] : '';
        if (id === 'email') return value.length > 0 && !checkEmail(value) ? window.lan.node.err[5] : '';
        if (id === 'license') return isNaN(parseInt(value)) || parseInt(value) < 0 ? window.lan.node.err[6] : '';
        if (id === 'featureList') return value.length > 4095 || !checkJSON(value) ? window.lan.node.err[7] : '';
        if (id === 'pluginList') return value.length > 4095 || !checkJSON(value) ? window.lan.node.err[8] : '';
        if (id === 'configuration') return value.length > 4095 || !checkJSON(value) ? window.lan.node.err[9] : '';
        if (id === 'remark') return value.length > 4095 ? window.lan.node.err[10] : '';
        return '';
    }

    presubmit() {
        if (!super.presubmit()) return false;
        this.value.validTime = this.value._validTime.unix();
        for (const key in this.value) if (key.charAt(0) === '_') delete this.value[key];
        return true;
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/node.basic', 'delete', {}, props.reference, 'del_diag', window.lan.node.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.node.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.list);
        this.reference.refresh();
    }
}



class Copy extends AbstractDialog {
    constructor(props) {
        super('/feature/node.basic/copy', 'post', { name: '' }, props.reference, 'copy_diag', window.lan.node.copy, window.lan.node.copy_submit);
    }

    options() {
        return [
            (<TextInput form={this} id="name" label={window.lan.node.copy_name} fullwidth />)
        ];
    }

    validate(id, value) {
        if (id === 'name') return value.length === 0 || value.length > 31 ? window.lan.node.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.node.manage, icon: (<ManageAccountsIcon fontSize="small" />), fun: () => { window.open(get_url('/feature/node.basic/manage?ID=' + props.row.ID), '_blank').focus(); } });
        items.push({});
        items.push({ name: window.lan.node.copy, icon: (<ContentCopyIcon fontSize="small" />), fun: () => { props.reference.copy_diag.value = { 'nodeID': props.row.ID, 'name': props.row.name }; props.reference.copy_diag.openmain(); } });
        items.push({ name: window.lan.node.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.node.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Node extends AbstractTable {

    constructor(props) {
        super('/feature/node.basic',
            [
                { sortindex: 0, label: window.lan.node.id },
                { sortindex: 1, label: window.lan.node.server_id },
                { sortindex: 6, label: window.lan.node.name },
                { sortindex: 2, label: window.lan.node.front_client },
                { sortindex: 4, label: window.lan.node.back_client },
                { sortindex: 7, label: window.lan.node.email },
                { sortindex: 9, label: window.lan.node.insert_time },
                { sortindex: 10, label: window.lan.node.update_time }
            ],
            window.lan.node.infobox, 'Node', props.path === '' ? '' : props.path.substr(1), '0$0', [
            { type: 0, name: window.lan.node.id, err: window.lan.node.err[11], fastsearch: true },
            { type: 0, name: window.lan.node.server_id, err: window.lan.node.err[12] },
            { type: 3, name: window.lan.node.state, fun: Node.getState, limit: 16 },
            { type: 4, name: window.lan.node.front_client, err: window.lan.node.err[13], fastsearch: true },
            { type: 4, name: window.lan.node.front_server, err: window.lan.node.err[14], fastsearch: true },
            { type: 4, name: window.lan.node.back_client, err: window.lan.node.err[15], fastsearch: true },
            { type: 4, name: window.lan.node.back_server, err: window.lan.node.err[16], fastsearch: true },
            { type: 4, name: window.lan.node.name, err: window.lan.node.err[17], fastsearch: true },
            { type: 4, name: window.lan.node.email, err: window.lan.node.err[18], fastsearch: true },
            { type: 3, name: window.lan.node.language, fun: Node.getLanguage, limit: 16 },
            { type: 1, name: window.lan.node.size, err: window.lan.node.err[19] },
            { type: 1, name: window.lan.node.license, err: window.lan.node.err[20] },
            { type: 5, name: window.lan.node.valid_time, err: window.lan.node.err[21] }
        ],
            [
                window.lan.node.id,
                window.lan.node.server_id,
                window.lan.node.front_client,
                window.lan.node.front_server,
                window.lan.node.back_client,
                window.lan.node.back_server,
                window.lan.node.name,
                window.lan.node.email,
                window.lan.node.language,
                window.lan.node.insert_time,
                window.lan.node.update_time,
                window.lan.node.size,
                window.lan.node.license,
                window.lan.node.valid_time
            ], true, true);
    }

    static getState(type) {
        if (type === 0) return window.lan.node.state_normal;
        if (type === 1) return window.lan.node.state_waiting;
        return '';
    }

    static getLanguage(type) {
        return type < Object.keys(window.lan.language).length ? Object.values(window.lan.language)[type] : '';
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.node.title);
        return this.pdraw([{ info: window.lan.node.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Copy reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={3}><Typography align="left"><b>{window.lan.node.size}:</b><br />{row.size}</Typography></Grid>
            <Grid item xs={3}><Typography align="left"><b>{window.lan.node.license}:</b><br />{row.license}</Typography></Grid>
            {row.ID > 1 ? <Grid item xs={3}><Typography align="left"><b>{window.lan.node.valid_time}:</b><br />{getUTCDateStr(row.validTime)}</Typography></Grid> : <Grid item xs={3}></Grid>}
            <Grid item xs={3}><Typography align="left"><b>{window.lan.node.language}:</b><br />{window.lan.language[row.language]}</Typography></Grid>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.node.feature_list}:</b><br />{JSON.stringify(JSON.parse(row.featureList), null, "\t")}</Typography></Grid>
            {row.ID > 1 ? <Grid item xs={12}><Typography align="left"><b>{window.lan.node.plugin_list}:</b><br />{JSON.stringify(JSON.parse(row.pluginList), null, "\t")}</Typography></Grid> : null}
            {row.ID > 1 ? <Grid item xs={12}><Typography align="left"><b>{window.lan.node.configuration}:</b><br />{JSON.stringify(JSON.parse(row.configuration), null, "\t")}</Typography></Grid> : null}
            <Grid item xs={12}><Typography align="left"><b>{window.lan.node.remark}:</b><br />{row.remark !== '' ? row.remark : window.lan.general.none}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) return row.serverID;
        if (cellindex === 3) return (<Stack direction="row" spacing={2}><Typography>{row.name}</Typography>
            {row.validTime < Math.floor(Date.now() / 1000) || row.license < row.size ? <Chip label={window.lan.node.unlicensed_tip} color="error" size="small" /> : null}
            {!(row.validTime < Math.floor(Date.now() / 1000) || row.license < row.size) && (row.validTime - Date.now() / 1000 < 30 * 86400 || 0.9 * row.license < row.size) ? <Chip label={window.lan.node.license_tip} color="warning" size="small" /> : null}
            {row.state !== 0 ? <Chip label={window.lan.node.operation_tip} color="secondary" size="small" /> : null}
        </Stack>);
        if (cellindex === 4) return (<Stack spacing={0}><Link href={row.frontClient} underline="none" target="_blank">{row.frontClient}</Link><Typography variant="caption">{row.frontServer}</Typography></Stack>);
        if (cellindex === 5) return (<Stack spacing={0}><Link href={row.backClient} underline="none" target="_blank">{row.backClient}</Link><Typography variant="caption">{row.backServer}</Typography></Stack>);
        if (cellindex === 6) return <Link href={"mailto:" + row.email} underline="none">{row.email}</Link>;
        if (cellindex === 7) return getTimeStr(row.insertTime);
        if (cellindex === 8) return getTimeStr(row.updateTime);
    }

    drawEmptyCell() {
        return window.lan.node.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.node.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.node.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_node = function (info) {
    return window.lan.loginfo.add_node.replace('%A%', info.id).replace('%B%', info.name).replace('%C%', info.server_id);
}

window.logfun.remove_nodes = function (info) {
    return window.lan.loginfo.remove_nodes.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_node = function (info) {
    return window.lan.loginfo.edit_node.replace('%A%', info.id).replace('%B%', info.name).replace('%C%', info.license).replace('%D%', getUTCDateStr(info.validTime));
}

window.logfun.copy_node = function (info) {
    return window.lan.loginfo.copy_node.replace('%A%', info.node_id).replace('%B%', info.id).replace('%C%', info.name);
}

window.logfun.manage_node = function (info) {
    return window.lan.loginfo.manage_node.replace('%A%', info.id);
}



export default Node;