import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { initialize_title, load, modify_title } from '../../main/App';
import AbstractTable from '../../main/AbstractTable';
import { TextInput, AbstractDialog } from '../../main/AbstractForm';
import { AbstractMenu } from '../../main/AbstractMenu';
import { getTimeStr } from '../../main/Utility';



class Add extends AbstractDialog {
    constructor(props) {
        super('/feature/server.basic', 'post', { 'name': '' }, props.reference, 'add_diag', window.lan.server.add, window.lan.server.add_submit);
    }

    options() {
        return [
            <TextInput form={this} id="address" label={window.lan.server.add_address} fullwidth />
        ];
    }

    validate(id, value) {
        if (id === 'address') return value.length < 1 || value.length > 255 ? window.lan.server.err[0] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.add(info.id);
        this.reference.refresh();
    }
}



class Edit extends AbstractDialog {
    constructor(props) {
        super('/feature/server.basic', 'put', {}, props.reference, 'edit_diag', window.lan.server.edit, window.lan.server.edit_submit);
    }

    options() {
        return [
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="administrator" label={window.lan.server.edit_administrator} fullwidth />
                <TextInput form={this} id="pass" label={window.lan.server.edit_pass} type="password" fullwidth />
            </Stack>),
            (<Stack direction="row" spacing={5}>
                <TextInput form={this} id="size" label={window.lan.server.edit_size} fullwidth />
                <TextInput form={this} id="license" label={window.lan.server.edit_license} fullwidth />
            </Stack>),
            (<TextInput form={this} id="remark" label={window.lan.server.edit_remark} fullwidth multiline rows={4} />),
        ];
    }

    validate(id, value) {
        if (id === 'administrator') return value.length > 31 ? window.lan.server.err[1] : '';
        if (id === 'pass') return value.length > 0 && (value.length < 8 || value.length > 32) ? window.lan.server.err[2] : '';
        if (id === 'size') return isNaN(parseInt(value)) || parseInt(value) < 0 ? window.lan.server.err[3] : '';
        if (id === 'license') return isNaN(parseInt(value)) || parseInt(value) < 0 ? window.lan.server.err[4] : '';
        if (id === 'remark') return value.length > 4095 ? window.lan.server.err[5] : '';
        return '';
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.update(info.id);
        this.reference.refresh();
    }
}



class Del extends AbstractDialog {
    constructor(props) {
        super('/feature/server.basic', 'delete', {}, props.reference, 'del_diag', window.lan.server.del, window.lan.general.submit);
    }

    options() {
        return [window.lan.server.del_tip];
    }

    result(result, info) {
        if (result === 0)
            this.reference.dm.remove(info.list);
        this.reference.refresh();
    }
}



class Menu extends AbstractMenu {

    constructor(props) {
        const items = [];
        items.push({ name: window.lan.server.node_tip, icon: (<FormatListBulletedIcon fontSize="small" />), fun: () => { load('/node/1$' + props.row.ID + '$0'); } });
        items.push({});
        items.push({ name: window.lan.server.edit, icon: (<EditIcon fontSize="small" />), fun: () => { props.reference.edit_diag.value = { ...props.row }; props.reference.edit_diag.openmain(); } });
        items.push({ name: window.lan.server.del, icon: (<ClearIcon fontSize="small" />), fun: () => { props.reference.del_diag.value = { "IDList": props.row.ID }; props.reference.del_diag.openmain(); } });
        super([{ title: window.lan.general.operation, items: items }]);
    }
}



class Server extends AbstractTable {

    constructor() {
        super('/feature/server.basic',
            [
                { sortindex: 0, label: window.lan.server.id },
                { sortindex: 1, label: window.lan.server.address },
                { sortindex: 2, label: window.lan.server.name },
                { sortindex: 3, label: window.lan.server.update_time },
                { sortindex: -1, label: window.lan.server.num },
                { sortindex: 4, label: window.lan.server.size },
                { sortindex: 5, label: window.lan.server.license }
            ],
            window.lan.server.infobox, 'Server', '', '0$0', [],
            [
                window.lan.server.id,
                window.lan.server.address,
                window.lan.server.name,
                window.lan.server.update_time,
                window.lan.server.size,
                window.lan.server.license
            ], true, true);
    }

    draw() {
        initialize_title();
        modify_title(2, window.lan.server.title);
        return this.pdraw([{ info: window.lan.server.title }], (<React.Fragment><Add reference={this} /><Edit reference={this} /><Del reference={this} /></React.Fragment>), this.tdraw());
    }

    drawMenu(row, rowindex) {
        return (<Menu key={Date.now()} reference={this} row={row} rowindex={rowindex} />);
    }

    drawDetail(row, rowindex) {
        return (<Grid container justifyContent="flex-start" sx={{ padding: '5px' }}>
            <Grid item xs={12}><Typography align="left"><b>{window.lan.server.remark}:</b><br />{row.remark !== '' ? row.remark : window.lan.general.none}</Typography></Grid>
        </Grid>);
    }

    drawCell(row, rowindex, cellindex) {
        if (cellindex === 1) return row.ID;
        if (cellindex === 2) {
            return (<Stack direction="row" spacing={2}><Typography>{row.address}</Typography>
                {Date.now() / 1000 - row.updateTime > 300 ? <Chip label={window.lan.server.offline_tip} color="secondary" size="small" /> : <Chip label={window.lan.server.online_tip} color="success" size="small" />}
                {row.siteSize > row.size || row.siteLicense > row.license ? <Chip label={window.lan.server.overflow_tip} color="warning" size="small" /> : null}
            </Stack>);
        }
        if (cellindex === 3) return row.name;
        if (cellindex === 4) return row.updateTime > 0 ? getTimeStr(row.updateTime) : '';
        if (cellindex === 5) return row.siteNum;
        if (cellindex === 6) return row.siteSize + ' / ' + row.size;
        if (cellindex === 7) return row.siteLicense + ' / ' + row.license;
    }

    drawEmptyCell() {
        return window.lan.server.empty;
    }

    drawToolbarLeft() {
        return (<Button variant="contained" disableElevation onClick={() => { if (this.state.selected.length === 0) return; this.del_diag.value = { "IDList": this.state.selected.join(',') }; this.del_diag.openmain(); }}>{window.lan.server.del}</Button>);
    }

    drawToolbarRight() {
        return (<Button variant="outlined" disableElevation onClick={() => { this.add_diag.openmain(); }}>{window.lan.server.add}</Button>);
    }
}



if (!window.logfun) window.logfun = {};

window.logfun.add_server = function (info) {
    return window.lan.loginfo.add_server.replace('%A%', info.id).replace('%B%', info.address);
}

window.logfun.remove_servers = function (info) {
    return window.lan.loginfo.remove_servers.replace('%A%', info.ids.join(', '));
}

window.logfun.edit_server = function (info) {
    return window.lan.loginfo.edit_server.replace('%A%', info.id);
}



export default Server;